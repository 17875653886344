body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: hsl(210, 36%, 96%);
    color: hsl(209, 61%, 16%);
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

h1,
h2,
h3,
h4 {
    letter-spacing: 0.1rem;
    text-transform: capitalize;
    line-height: 1.25;
    margin-bottom: 0.75rem;
}

h3 {
    font-size: 1.25rem;
}

h4 {
    font-size: 0.875rem;
}

p {
    margin-bottom: 1.25rem;
    color: hsl(210, 22%, 49%);
}

.section {
    width: 90vw;
    margin: 0 auto;
    max-width: 1170px;
}

/* @media screen and (min-width: 992px) {
    .section {
        width: 95vw;
    }
} */

.menu {
    padding: 0rem 0;
}

.title {
    text-align: center;
    margin-bottom: 2rem;
    margin-top: 0;
}

.underline {
    width: 6rem;
    height: 0.25rem;
    background: #c59d5f;
    margin-left: auto;
    margin-right: auto;
}

.btn-container {
    margin-bottom: 4rem;
    display: flex;
    justify-content: center;
}

.filter-btn {
    background: transparent;
    border-color: transparent;
    font-size: 1rem;
    text-transform: capitalize;
    margin: 0 0.5rem;
    letter-spacing: 1px;
    padding: 0.375rem 0.75rem;
    color: hsl(360, 71%, 66%);
    cursor: pointer;
    transition: all 0.3s linear;
    border-radius: 0.25rem;
}

.active {
    border-bottom: 2px solid;
}

.section-center1 {
    width: 90vw;
    margin: 0 auto;
    max-width: 1170px;
    display: grid;
    gap: 3rem 2rem;
    justify-items: center;
}

.menu-item1 {
    display: grid;
    gap: 1rem 2rem;
    /* background: #fff; */
    /* border-radius: 0.25rem; */
    /* box-shadow: 0 0 3px #ccc; */
    padding: 1rem;
    /* transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out; */
    /* max-width: 25rem; */
    /* width: 100%; */
    /* justify-items: start; */
    max-width: 25rem;
}

.menu-item1:hover {
    /* transform: scale(1.05);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2); */
}

.photo {
    object-fit: cover;
    height: 250px;
    width: 100%;
    border: 0.25rem solid hsl(360, 71%, 66%);
    border-radius: 0.25rem;
    display: block;
}

.logo {
    object-fit: cover;
    height: auto;
    width: 200px;
    border-radius: 0.25rem;
}

.item-info header {
    /* display: inline-flex; */
    justify-content: space-between;
    border-bottom: 0.5px dotted hsl(210, 22%, 49%);

}

.item-info h4 {
    margin-bottom: 0.5rem;
}

.price {
    color: #c59d5f;
}

.item-text {
    margin-bottom: 0;
    padding-top: 1rem;
}


/* @media screen and (min-width: 768px) {
    .menu-item {
        grid-template-columns: 225px 1fr;
        gap: 0 1.25rem;
        max-width: 40rem;
    }

    .photo {
        height: 175px;
    }
}*/

@media screen and (min-width: 768px) {
    .section-center1 {
        width: 95vw;
        grid-template-columns: 1fr 1fr 1fr !important;
    }

    .photo {
        height: 250px;
    }
}

/* .sections {
    margin-top: 0% !important;
}

.menu {
    margin-top: 0px !important;
} */
.button-container {
    float: right;
}

/* .photo-link {
    text-decoration: none;
} */
.menu-item1 h4 {
    color: #c59d5f;
}

/* .submenu1 {
    display: none;
}

.submenu1:active {
    display: block;
} */
/* .submenu1 {
    display: none;
}


.active-submenu1 {
    display: block;
} */

.menu-item1 {
    cursor: pointer;
    /* Change 'default' to your preferred cursor style */
}