/* #components-layout-demo-custom-trigger .trigger {
    padding: 0 24px;
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
    color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
    height: 32px;
    margin: 16px;
    background: rgba(255, 255, 255, 0.3);
} */
.custom-submenu {
    background-color: transparent;
}

.site-layout .site-layout-background {
    background: #fff;
    box-shadow: 0 0 3px #ccc;
    border-radius: 5px;
}

.ant-layout-has-sider {
    padding: 15px !important;
}

.ant-layout-sider {
    border-radius: 10px !important;

}

.ant-layout-sider-children {
    padding-top: 15px;
    padding-right: 20px;
    height: 95vh;
}

.ant-layout-header {
    margin: 0 10px !important;
    display: flex;
    align-items: center;
    border-radius: 5px;
    justify-content: space-between;
}

.anticon.svg {
    height: 20px;
    width: 20px;
    font-size: 20px;
}

.logo h3 {
    color: white;
    font-weight: bold;
    text-align: center;

}

.logo img {
    display: block;
    margin: 0 auto;
}

.ant-menu-item-selected {
    background-color: #d27842 !important;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.ant-menu-item {
    color: #c59d5f;
    font-size: 1rem;
}

.ant-menu-item:hover {
    background-color: #d48757 !important;
    color: #fff !important;
    transition: background-color 0.3s;
}

.ant-btn {
    background-color: #c59d5f;
}

.ant-btn:hover {
    background-color: #c59d5f !important;
}

.cart-count {
    cursor: pointer;
}


.spinner {
    z-index: 10;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%);
}

.spinner-border {
    height: 100px;
    width: 100px;

}

.ant-layout-content {
    height: 80vh;
    overflow-y: scroll;
}

.ant-menu-title-content {
    color: #c59d5f;
    font-size: 1rem;
}

.ant-menu-submenu-arrow {
    color: #c59d5f;
    font-size: 1rem;
}

.anticon anticon-file {
    color: #c59d5f;
    font-size: 1rem;
}

.ant-menu-submenu-title {
    color: #c59d5f !important;
    font-size: 1rem;
}

.ant-menu-submenu-open {
    background-color: #d48757;
    /* border-top-right-radius: 10px;
    border-bottom-right-radius: 10px; */
}

.ant-menu-submenu-active {
    background-color: #d48757;
    /* border-top-right-radius: 10px;
    border-bottom-right-radius: 10px; */
}

/* .ant-menu-submenu-inline {
    background-color: #d48757;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
} */

/* .ant-menu-submenu-open {
    background-color: white;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
} */

/* button {
    background-color: rgb(15, 105, 15) !important;
    color: white !important;
    border: None;
    box-shadow: None;
    outline: None;
} */

.ant-modal-close-x {
    background-color: white !important;
    color: black !important;

}